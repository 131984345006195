import store from '@/store'

export default [
  {
    path: '/informehogar',
    name: 'informehogar',
    component: () => import('@/views/informeHogar/InformeHogar.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Informe Hogar',
      breadcrumb: [
        {
          text: 'Informe Hogar',
          active: true,
        },
      ],
    },
  },
]
