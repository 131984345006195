export default function avance() {
  return 0
}

export function getTipoEnseñanzas(state) {
  return state.tipoEnseñanzas
}

export function getCursosActivos(state) {
  return state.cursosActivos
}

export function getAsignaturasActivas(state) {
  return state.asignaturasActivas
}
