import axios from 'axios'

export default async function avances() {
  return 0
}

export async function fetchTipoEnseñanzas({ commit }, idUsuarioEstablecimiento) {
  try {
    const { data } = await axios.get(`avances/tipoEnseñanza/${idUsuarioEstablecimiento}`)
    commit('setTipoEnseñanzas', data)
  } catch (e) {
    commit('avanceError', e.response.data)
  }
}

// * Resumen Anual
// * Resumen Aprendizaje
// * Aprendizaje
export async function fetchCursosEstablecimientoActivos({ commit, rootState }, idEstablecimiento) {
  try {
    const { idPeriodoHistorico } = rootState.periodos
    const { data } = await axios.get(`curso/establecimiento/activo/${idEstablecimiento}/${idPeriodoHistorico}`)
    commit('setCursosActivos', data)
  } catch (e) {
    commit('avanceError', e.response.data)
  }
}

export async function fetchAsignaturasCursoActivas({ commit }, idCurso) {
  try {
    const { data } = await axios.get(`/avances/asignatura/curso/activa/${idCurso}`)
    commit('setAsignaturasActivas', data)
  } catch (e) {
    commit('avanceError', e.response.data)
  }
}

export async function updateAvances({ commit }, avance) {
  try {
    commit('avanceErrorNull')
    await axios({
      method: 'PUT',
      url: `/avances/${avance.id}`,
      data: {
        rbd: avance.rbd,
        nombre: avance.nombre,
        insignia: avance.insignia,
        correo: avance.correo,
        telefono: avance.telefono,
        direccion: avance.direccion,
        dependencia: avance.dependencia,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('avanceError', e.response.data)
  }
}
