export default function sistema() {
  return 0
}

export function getInquilinos(state) {
  return state.inquilinos
}

export function getInquilinosActivos(state) {
  return state.inquilinosActivos
}
