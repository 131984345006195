export function setPeriodos(state, periodos) {
  state.periodos = periodos
}

export function setPeriodo(state, periodo) {
  state.selectedPeriodo = periodo
}

export function setPeriodoHistorico(state, idPeriodo) {
  state.idPeriodoHistorico = idPeriodo
}

export function periodoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.periodos = []
}

export function periodoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
