export function setPrioritarios(state, prioritarios) {
  state.prioritarios = prioritarios
}

export function setPrioritariosActivos(state, prioritarios) {
  state.prioritariosActivos = prioritarios
}

export function setPrioritariosAsignatura(state, prioritarios) {
  state.prioritariosAsignatura = prioritarios
}

export function setPrioritario(state, prioritario) {
  state.selectedPrioritario = prioritario
}

export function updatePrioritarioEstado(state, payload) {
  const prioritario = state.prioritarios.find(u => u.id === payload.id)
  if (prioritario) {
    state.done = !prioritario.done
  }
}

export function prioritarioError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.prioritarios = []
}

export function prioritarioErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
