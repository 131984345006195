export default function usuario() {
  return 0
}

export function getUsuarios(state) {
  return state.usuarios
}

export function getUsuariosActivos(state) {
  return state.usuariosActivos
}

export function getDocentesActivos(state) {
  return state.docentesActivos
}

export function getDocenteAsignaturas(state) {
  return state.docenteAsignaturas
}
