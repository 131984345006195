export function setEjes(state, ejes) {
  state.ejes = ejes
}

export function setEjesActivos(state, ejes) {
  state.ejesActivos = ejes
}

export function setEjesAsignatura(state, ejes) {
  state.ejesAsignatura = ejes
}

export function setEje(state, eje) {
  state.selectedEje = eje
}

export function updateEjeEstado(state, payload) {
  const eje = state.ejes.find(u => u.id === payload.id)
  if (eje) {
    state.done = !eje.done
  }
}

export function ejeError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.ejes = []
}

export function ejeErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
