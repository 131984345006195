export function setPuntajesIndicadores(state, puntajesIndicadores) {
  state.puntajesIndicadores = puntajesIndicadores
}

export function setPromedio(state, data) {
  const puntajesIndicadoresDuplicado = state.puntajesIndicadores
  const puntajesAlumno = puntajesIndicadoresDuplicado.find(pi => pi.idAlumno === data.idAlumno)
  puntajesAlumno.promedio = {}
  puntajesAlumno.promedio = data.promedio
  state.puntajesIndicadores = []
  state.puntajesIndicadores = puntajesIndicadoresDuplicado
}

export function setPuntajesIndicadoresTransformacion(state, puntajesIndicadoresTransformacion) {
  state.puntajesIndicadoresTransformacion = puntajesIndicadoresTransformacion
}

export function puntajeIndicadorError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.puntajeIndicadores = []
}

export function puntajeIndicadorErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
