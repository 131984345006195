import axios from 'axios'

export default async function ldConexion() {
  return 0
}

export async function fetchLdConexiones({ commit }, idPeriodo) {
  try {
    const { data } = await axios.get(`dash/conexionLd/getLogs/${idPeriodo}`)
    commit('setLds', data)
  } catch (e) {
    commit('ldError', e.response.data)
  }
}

export async function addLdConexion({ commit, dispatch }, datos) {
  try {
    const { data } = await axios({
      method: 'POST',
      url: 'dash/conexionLd/addLog',
      data: {
        idPeriodo: datos.idPeriodo,
        logs: datos.logs,
      },
    })
    commit('setResponse', data)
    dispatch('fetchLdConexiones', datos.idPeriodo)
  } catch (e) {
    commit('ldError', e.response.data)
  }
}
