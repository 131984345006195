export function setNotaConversion(state, notaConversion) {
  state.notaConversion = notaConversion
}

export function notaConversionError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.notaConversion = []
}

export function notaConversionErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
