import axios from 'axios'

export default async function periodos() {
  return 0
}

export async function fetchPeriodos({ commit }) {
  try {
    const { data } = await axios.get('periodos')
    commit('setPeriodos', data)
  } catch (e) {
    commit('periodoError', e.response.data)
  }
}

export function setPeriodoHistorico({ commit }, idPeriodo) {
  commit('setPeriodoHistorico', idPeriodo)
}
// export async function addPeriodos({ commit }, periodo) {
//   try {
//     commit('periodoErrorNull')
//     await axios({
//       method: 'POST',
//       url: 'periodos',
//       data: {
//         nombre: periodo.nombre,
//       },
//     })
//   } catch (e) {
//     commit('periodoError', e.response.data)
//   }
// }

// export async function updatePeriodos({ commit }, periodo) {
//   try {
//     commit('periodoErrorNull')
//     await axios({
//       method: 'PUT',
//       url: `/periodos/${periodo.id}`,
//       data: {
//         nombre: periodo.nombre,
//       },
//     })
//   } catch (e) {
//     commit('periodoError', e.response.data)
//   }
// }

// export async function removePeriodos({ commit, dispatch }, id) {
//   try {
//     await axios({
//       method: 'DELETE',
//       url: `/periodos/${id}`,
//     })
//     // actualizamos lista de periodos
//     dispatch('fetchPeriodos')
//   } catch (e) {
//     commit('periodoError', e.response.data)
//   }
// }
