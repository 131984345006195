import axios from 'axios'

export default async function cursos() {
  return 0
}

export async function fetchCursos({ commit }) {
  try {
    const { data } = await axios.get('cursos')
    commit('setCursos', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function fetchCursosActivos({ commit }) {
  try {
    const { data } = await axios.get('cursos/activos')
    commit('setCursosActivos', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

// * avances
export async function fetchCursosUsuario({ commit, rootState }) {
  try {
    const { idPeriodoHistorico } = rootState.periodos
    const { data } = await axios.get(`cursos/usuario/${idPeriodoHistorico}`)
    commit('setCursos', data)
    return data
  } catch (e) {
    return e.response.data
  }
}

export async function fetchCursosActivosEstablecimiento({ commit }, idEstablecimiento) {
  try {
    const { data } = await axios.get(`cursos/activos/establecimiento/${idEstablecimiento}`)
    commit('setCursosActivosEstablecimiento', data)
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function ordenarLista({ commit }, curso) {
  try {
    commit('cursoErrorNull')
    await axios({
      method: 'PUT',
      url: `/cursos/ordenar/lista/${curso.idCurso}`,
      data: {
        lista: curso.lista,
      },
    })
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function addCursos({ commit }, curso) {
  try {
    commit('cursoErrorNull')
    await axios({
      method: 'POST',
      url: 'cursos',
      data: {
        idEstablecimiento: curso.idEstablecimiento.id,
        cantidad: curso.cantidad,
        idGrado: curso.idGrado.id,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function updateCursos({ commit }, curso) {
  try {
    commit('cursoErrorNull')
    await axios({
      method: 'PUT',
      url: `/cursos/${curso.id}`,
      data: {
        nombre: curso.nombre,
        estado: curso.estado,
      },
    })
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function updateCursoEstado({ commit, dispatch }, curso) {
  let est = ''
  try {
    commit('cursoErrorNull')
    if (curso.estado === 'Inactivo') {
      est = 'Activo'
    } else if (curso.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/cursos/${curso.id}`,
      data: {
        nombre: curso.nombre,
        estado: est,
      },
    })
    // actualizamos lista de cursos
    dispatch('fetchCursos')
    dispatch('fetchCursosActivas')
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}

export async function removeCursos({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/cursos/${id}`,
    })
    // actualizamos lista de cursos
    dispatch('fetchCursos')
  } catch (e) {
    commit('cursoError', e.response.data)
  }
}
