import axios from 'axios'

export default async function indicadores() {
  return 0
}

export async function fetchIndicadoresObjetivo({ commit }, datos) {
  try {
    const {
      idObjetivo,
      tipo,
    } = datos
    const { data } = await axios.get(`indicadores/objetivo/${idObjetivo}/${tipo}`)
    commit('setIndicadoresObjetivo', data)
  } catch (e) {
    commit('indicadorError', e.response.data)
  }
}

export async function fetchIndicadoresPersonalizados({ commit }, idObjetivo) {
  try {
    const { data } = await axios.get(`indicadores/personalizados/${idObjetivo}`)
    commit('setIndicadoresPersonalizados', data)
  } catch (e) {
    commit('indicadorError', e.response.data)
  }
}
