export default function curso() {
  return 0
}

export function getCursos(state) {
  return state.cursos
}

export function getCursosActivos(state) {
  return state.cursosActivos
}

export function getCursosActivosEstablecimiento(state) {
  return state.cursosActivosEstablecimiento
}
