import axios from 'axios'

export default async function puntajesIndicador() {
  return 0
}

export async function fetchPuntajesIndicadores({ commit }, datos) {
  try {
    const {
      idPeriodo,
      idCurso,
      idAsignatura,
      idObjetivo,
      tipo,
    } = datos
    const valores = `${idPeriodo}/${idCurso}/${idAsignatura}/${idObjetivo}/${tipo}`
    const { data } = await axios.get(`/puntajes/indicadores/${valores}`)
    commit('setPuntajesIndicadores', data)
  } catch (e) {
    commit('puntajeIndicadorError', e.response.data)
  }
}

export async function fetchPuntajesIndicadoresTransformacion({ commit }) {
  try {
    const { data } = await axios.get('/puntajes/indicadores/transformacion')
    commit('setPuntajesIndicadoresTransformacion', data)
  } catch (e) {
    commit('puntajeIndicadorError', e.response.data)
  }
}

export async function updatePuntajeIndicador({ commit }, puntajeIndicador) {
  try {
    commit('puntajeIndicadorErrorNull')
    const tipoIndicador = puntajeIndicador.tipo === 'Ministerio'
      ? 'Normal'
      : puntajeIndicador.tipo
    const { data } = await axios({
      method: 'PUT',
      url: `/puntajes/${puntajeIndicador.puntaje}`,
      data: {
        idPeriodo: puntajeIndicador.idPeriodo,
        idCurso: puntajeIndicador.idCurso,
        idAsignatura: puntajeIndicador.idAsignatura,
        idObjetivo: puntajeIndicador.idObjetivo,
        idIndicador: puntajeIndicador.idIndicador,
        idAlumno: puntajeIndicador.idAlumno,
        tipoObjetivo: puntajeIndicador.tipoObjetivo,
        puntaje: puntajeIndicador.puntaje,
        tipoIndicador,
        estado: 'Activo',
      },
    })
    const dataSet = {
      promedio: data.promedio,
      idAlumno: puntajeIndicador.idAlumno,
    }
    commit('setPromedio', dataSet)
  } catch (e) {
    commit('puntajeIndicadorError', e.response.data)
  }
}

export async function updatePuntajeIndicadorPersonalizado({ commit }, puntajeIndicador) {
  try {
    commit('puntajeIndicadorErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `/puntajes/${puntajeIndicador.puntaje}`,
      data: {
        idPeriodo: puntajeIndicador.idPeriodo,
        idCurso: puntajeIndicador.idCurso,
        idAsignatura: puntajeIndicador.idAsignatura,
        idObjetivo: puntajeIndicador.idObjetivo,
        idIndicador: puntajeIndicador.idIndicador,
        idAlumno: puntajeIndicador.idAlumno,
        puntaje: puntajeIndicador.puntaje,
        tipoObjetivo: puntajeIndicador.tipoObjetivo,
        tipoIndicador: 'Personalizado',
        estado: 'Activo',
      },
    })
    const dataSet = {
      promedio: data.promedio,
      idAlumno: puntajeIndicador.idAlumno,
    }
    commit('setPromedio', dataSet)
  } catch (e) {
    commit('puntajeIndicadorError', e.response.data)
  }
}
