export function setIndicadores(state, indicadores) {
  state.indicadores = indicadores
}

export function setIndicadoresObjetivo(state, indicadores) {
  state.indicadoresObjetivo = indicadores
}

export function setIndicadoresPersonalizados(state, indicadores) {
  state.indicadoresPersonalizados = indicadores
}

export function indicadorError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.indicadores = []
}

export function indicadorErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
