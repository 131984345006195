export default {
  notas: [],
  notasAll: [],
  error: false,
  errorMessage: [],

  status: null,
  message: null,
  cursoResponse: null,
  gradoResponse: null,
  notaResponse: null,
}
