import axios from 'axios'

export default async function informes() {
  return 0
}

export async function readPDF({ commit }, pdf) {
  try {
    const {
      idAlumno,
      tipoInforme,
    } = pdf
    const { baseURL } = axios.defaults
    const url = `${baseURL}/informe/hogar/${idAlumno}/read/${tipoInforme}`
    window.open(url, '_blank')
  } catch (e) {
    commit('informeError', e.response.data)
  }
}

export async function downloadPDF({ commit }, pdf) {
  try {
    const {
      idAlumno,
      tipoInforme,
    } = pdf
    const { baseURL } = axios.defaults
    const url = `${baseURL}/informe/hogar/${idAlumno}/download/${tipoInforme}`
    window.location.href = url
  } catch (e) {
    commit('informeError', e.response.data)
  }
}

// Eliminar
export async function imprimirResumenAnual({ commit }, dataAlumnos) {
  try {
    const {
      idCurso,
      fields,
      alumnosNotas,
      tipo,
    } = dataAlumnos
    await axios({
      method: 'POST',
      url: 'informes/resumenAnualPdf/',
      data: {
        idCurso,
        fields,
        alumnosNotas,
        tipo,
      },
    })
  } catch (e) {
    commit('informeError', e.response.data)
  }
}
