import axios from 'axios'

export default async function grados() {
  return 0
}

export async function fetchGrados({ commit }) {
  try {
    const { data } = await axios.get('grados')
    commit('setGrados', data)
  } catch (e) {
    commit('gradoError', e.response.data)
  }
}
