import axios from 'axios'

export default async function alumnos() {
  return 0
}

export async function fetchAlumnos({ commit }) {
  try {
    const { data } = await axios.get('alumnos')
    commit('setAlumnos', data)
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}

export async function fetchAlumnosPeriodo({ commit }) {
  try {
    const { data } = await axios.get('alumnos/periodo')
    commit('setAlumnos', data)
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}

export async function fetchAlumnosRetirados({ commit }) {
  try {
    const { data } = await axios.get('alumnos/activos')
    commit('setAlumnosActivos', data)
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}

export async function fetchAlumnosCurso({ commit }, idCurso) {
  try {
    const { data } = await axios.get(`alumnos/curso/${idCurso}`)
    commit('setAlumnosCurso', data)
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}

export async function addAlumnos({ commit }, alumno) {
  try {
    commit('alumnoErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'alumnos',
      data: {
        tipoDocumento: alumno.tipoDocumento,
        rut: alumno.rut,
        fechaNacimiento: alumno.fechaNacimiento,
        genero: alumno.genero,
        nombres: alumno.nombres,
        primerApellido: alumno.primerApellido,
        segundoApellido: alumno.segundoApellido,
        correo: alumno.correo,
        idEstablecimiento: alumno.idEstablecimiento,
        idCurso: alumno.idCurso,
        numMatricula: alumno.numMatricula,
        idPrioritario: alumno.idPrioritario,
        paci: alumno.paci,
        pie: alumno.pie,
        idDiagnostico: alumno.idDiagnostico,
        estado: 'Activo',
      },
    })
    return data
  } catch (error) {
    commit('alumnoError', error.response.data)
  }
}

export async function updateAlumnos({ commit }, alumno) {
  try {
    commit('alumnoErrorNull')
    await axios({
      method: 'PUT',
      url: `/alumnos/${alumno.id}`,
      data: {
        tipoDocumento: alumno.tipoDocumento,
        rut: alumno.rut,
        fechaNacimiento: alumno.fechaNacimiento,
        genero: alumno.genero,
        nombres: alumno.nombres,
        primerApellido: alumno.primerApellido,
        segundoApellido: alumno.segundoApellido,
        correo: alumno.correo,
        idEstablecimiento: alumno.idEstablecimiento,
        idCurso: alumno.idCurso,
        numMatricula: alumno.numMatricula,
        idPrioritario: alumno.idPrioritario,
        paci: alumno.paci,
        pie: alumno.pie,
        idDiagnostico: alumno.idDiagnostico,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}

export async function updateAlumnoPeriodo({ commit }, alumno) {
  try {
    commit('alumnoErrorNull')
    const fecha = new Date()
    const dia = fecha.getDate()
    const mes = (fecha.getMonth() + 1)
    const year = fecha.getFullYear()
    const fechaInicioPeriodoActivo = `${year}-${mes}-${dia}`
    await axios({
      method: 'PUT',
      url: `/alumnos/periodoActivo/${alumno.id}`,
      data: {
        idPeriodoActivo: alumno.idPeriodoActivo,
        fechaInicioPeriodoActivo,
      },
    })
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}

export async function updateAlumnoEstado({ commit, dispatch }, alumno) {
  let est = ''
  try {
    commit('alumnoErrorNull')
    if (alumno.estado === 'Inactivo') {
      est = 'Activo'
    } else if (alumno.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/alumnos/${alumno.id}`,
      data: {
        nombre: alumno.nombre,
        estado: est,
      },
    })
    // actualizamos lista de alumnos
    dispatch('fetchAlumnos')
    dispatch('fetchAlumnosActivas')
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}

export async function removeAlumnos({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/alumnos/${id}`,
    })
    // actualizamos lista de alumnos
    dispatch('fetchAlumnos')
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}
