export default function periodo() {
  return 0
}

export function getPeriodos(state) {
  return state.periodos
}

export function getPeriodoHistorico(state) {
  return state.idPeriodoHistorico
}
