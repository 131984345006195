export default [
  {
    path: '/inquilinos',
    name: 'inquilinos',
    component: () => import('@/views/inquilinos/Inquilinos.vue'),
    meta: {
      pageTitle: 'Inquilinos',
      breadcrumb: [
        {
          text: 'Inquilinos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inquilinos/create',
    name: 'inquilinos-create',
    component: () => import('@/views/inquilinos/InquilinosCreate.vue'),
    meta: {
      pageTitle: 'Crear inquilino',
      breadcrumb: [
        {
          text: 'Inquilinos',
          to: '/inquilinos',
        },
        {
          text: 'Crear Inquilino',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inquilinos/update',
    name: 'inquilinos-update',
    component: () => import('@/views/inquilinos/InquilinosUpdate.vue'),
    meta: {
      pageTitle: 'Editar inquilino',
      breadcrumb: [
        {
          text: 'Inquilinos',
          to: '/inquilinos',
        },
        {
          text: 'Editar Inquilino',
          active: true,
        },
      ],
    },
  },
]
