import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'axios'
// import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// MANTENER SESION
require('@/store/:auth/subscriber')

// Define baseURL de la api
// axios.defaults.baseURL = 'http://api-brujula.test/api'
// axios.defaults.baseURL = 'http://192.168.1.86:8000/api'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api'

axios.defaults.baseURL = 'https://api.escuelarepublicadefrancia.cl/api'

// Axios
// Vue.use(VueAxios, axios)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = true

// Envía el token a store/auth/index/attempt
// Para consultar los datos del usuario authenticado
store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
