export function setIndicadorPersonalizado(state, indicadorPersonalizado) {
  state.indicadorPersonalizado = indicadorPersonalizado
}

export function setIndicadorPersonalizadoAll(state, indicadorPersonalizado) {
  state.indicadorPersonalizadoAll = indicadorPersonalizado
}

export function setIndicadorPersonalizadoObjetivoAprobados(state, indicadorPersonalizado) {
  state.indicadorPersonalizadoObjetivoAprobados = indicadorPersonalizado
}

export function indicadorPersonalizadoAllError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.indicadorPersonalizadoAll = []
}

export function indicadorPersonalizadoObjetivosAprobadosError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.indicadorPersonalizadoObjetivoAprobados = []
}

export function indicadorPersonalizadoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
