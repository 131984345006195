export function setInquilinos(state, inquilinos) {
  state.inquilinos = inquilinos
}

export function setInquilino(state, inquilino) {
  state.selectedInquilino = inquilino
}

export function updateInquilinoEstado(state, payload) {
  const inquilino = state.inquilinos.find(u => u.id === payload.id)
  if (inquilino) {
    state.done = !inquilino.done
  }
}

export function inquilinoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.inquilinos = []
}

export function inquilinoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
