export function setLds(state, ldConexiones) {
  state.ldConexiones = ldConexiones
}

export function ldError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.ldConexiones = []
}

export function setResponse(state, payload) {
  state.state = payload.state
  state.message = payload.message
  state.ldConexiones = []
}

export function ldErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
