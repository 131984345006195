export default function objetivo() {
  return 0
}

export function getObjetivos(state) {
  return state.objetivos
}

export function getObjetivosAsignatura(state) {
  return state.objetivosAsignatura
}

export function getObjetivosTrabajados(state) {
  return state.objetivosTrabajados
}

export function getObjetivosBetwen(state) {
  return state.objetivosBetwen
}

export function getObjetivosActivos(state) {
  return state.objetivosActivos
}
