export function setObjetivos(state, objetivos) {
  state.objetivos = objetivos
}

export function setObjetivosActivos(state, objetivos) {
  state.objetivosActivos = objetivos
}

export function setObjetivosAsignatura(state, objetivos) {
  state.objetivosAsignatura = objetivos
}

export function setObjetivosTrabajados(state, objetivos) {
  state.objetivosTrabajados = objetivos
}

export function setObjetivosBetwen(state, objetivosBetwen) {
  state.objetivosBetwen = objetivosBetwen
}

export function setObjetivo(state, objetivo) {
  state.selectedObjetivo = objetivo
}

export function updateObjetivoEstado(state, payload) {
  const objetivo = state.objetivos.find(u => u.id === payload.id)
  if (objetivo) {
    state.done = !objetivo.done
  }
}

export function setResponse(state, apiResponse) {
  state.API_RESPONSE = apiResponse
}

export function objetivoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.objetivos = []
}

export function objetivoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
