export default function puntajeIndicador() {
  return 0
}

export function getPuntajesIndicadores(state) {
  return state.puntajesIndicadores
}

export function getPuntajesIndicadoresTransformacion(state) {
  return state.puntajesIndicadoresTransformacion
}
