import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    authErrorMessage: null,
  },
  getters: {
    // Permite leer la info del state
    authenticated(state) {
      return state.token && state.user
    },
    user(state) {
      return state.user
    },
    authErrorMessage(state) {
      return state.authErrorMessage
    },
    authLibroDigitalToken(state) {
      return state.token
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, data) {
      state.user = data
    },
    SET_ERROR(state, data) {
      state.authErrorMessage = data
    },
  },
  actions: {
    async signInLibroDigital({ commit, dispatch }, credenciales) {
      // Post a la api para singin
      try {
        const { data } = await axios.post('https://apilibrodigital.escuelarepublicadefrancia.cl/v1/login', credenciales)
        // Enviamos el token a la actions attemptLibroDigital
        return dispatch('attemptLibroDigital', data.access_token)
      } catch (e) {
        commit('SET_ERROR', e.response.data.error)
        return e.response.data
      }
    },
    async attemptLibroDigital({ commit, state }, token) {
      if (token) {
        commit('SET_TOKEN', token)
      }
      commit('SET_ERROR', null)
      if (!state.token) {
        return
      }
      try {
        const { data } = await axios.request({
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
          url: 'https://apilibrodigital.escuelarepublicadefrancia.cl/v1/me',
        })
        // const { data } = await axios.get('https://apilibrodigital.escuelarepublicadefrancia.cl/v1/me')
        // almacena los datos del usuario autenticado en los getters
        commit('SET_USER', data[0])
      } catch (e) {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
        commit('SET_ERROR', e.response.data)
      }
    },
    signOutLibroDigital({ commit }) {
      // https://apilibrodigital.escuelarepublicadefrancia.cl/v1/
      return axios.post('https://apilibrodigital.escuelarepublicadefrancia.cl/v1/logout')
        .then(() => {
          // Remove userData from localStorage
          commit('SET_TOKEN', null)
          commit('SET_USER', null)
        }).catch(() => {
          commit('SET_TOKEN', null)
          commit('SET_USER', null)
        })
    },
  },
}
