import axios from 'axios'

export default async function ejes() {
  return 0
}

// export async function fetchEjes({ commit }) {
//   try {
//     const { data } = await axios.get('ejes')
//     commit('setEjes', data)
//   } catch (e) {
//     commit('ejeError', e.response.data)
//   }
// }

// export async function fetchEje({ commit }) {
//   try {
//     const { data } = await axios.get('ejes')
//     commit('setEjes', data)
//   } catch (e) {
//     commit('ejeError', e.response.data)
//   }
// }

export async function fetchEjesAsignatura({ commit }, idAsignatura) {
  try {
    const { data } = await axios.get(`ejes/asignatura/${idAsignatura}`)
    commit('setEjesAsignatura', data)
  } catch (e) {
    commit('ejeError', e.response.data)
  }
}

export async function fetchEjesAsignaturaDistinct({ commit }, idAsignatura) {
  try {
    const { data } = await axios.get(`ejes/asignatura/distinct/${idAsignatura}`)
    commit('setEjesAsignatura', data)
  } catch (e) {
    commit('ejeError', e.response.data)
  }
}

export async function addEjes({ commit }, eje) {
  try {
    commit('ejeErrorNull')
    await axios({
      method: 'POST',
      url: 'ejes',
      data: {
        idAsignatura: eje.idAsignatura,
        ejes: eje.ejes,
      },
    })
  } catch (e) {
    commit('ejeError', e.response.data)
  }
}

// export async function updateEjes({ commit }, eje) {
//   try {
//     commit('ejeErrorNull')
//     await axios({
//       method: 'PUT',
//       url: `/ejes/${eje.id}`,
//       data: {
//         rbd: eje.rbd,
//         nombre: eje.nombre,
//         insignia: eje.insignia,
//         correo: eje.correo,
//         telefono: eje.telefono,
//         direccion: eje.direccion,
//         dependencia: eje.dependencia,
//         estado: 'Activo',
//       },
//     })
//   } catch (e) {
//     commit('ejeError', e.response.data)
//   }
// }

// export async function removeEjes({ commit, dispatch }, id) {
//   try {
//     await axios({
//       method: 'DELETE',
//       url: `/ejes/${id}`,
//     })
//     // actualizamos lista de ejes
//     dispatch('fetchEjes')
//   } catch (e) {
//     commit('ejeError', e.response.data)
//   }
// }
