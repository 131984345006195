import axios from 'axios'

export default async function notasConversion() {
  return 0
}

export async function fetchNotaConversion({ commit }, dataNotaConversion) {
  // try {
  const {
    cantidadIndicadores,
    puntajeObtenido,
  } = dataNotaConversion
  const { data } = await axios.get(`notasConversion/${cantidadIndicadores}/${puntajeObtenido}`)
  // console.log('cantidadIndicadores :', cantidadIndicadores)
  // console.log('puntajeObtenido :', puntajeObtenido)

  commit('setNotaConversion', data)
  // } catch (e) {
  //   commit('notaConversionError', e.response.data)
  // }
}
