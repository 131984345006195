export default function nota() {
  return 0
}

export function getNotas(state) {
  return state.notas
}

export function getNotasAll(state) {
  return state.notasAll
}
