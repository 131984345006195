export default function eje() {
  return 0
}

export function getEjes(state) {
  return state.ejes
}

export function getEjesAsignatura(state) {
  return state.ejesAsignatura
}

export function getEjesActivos(state) {
  return state.ejesActivos
}
