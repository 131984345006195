export function setTipoEnseñanzas(state, tipoEnseñanzas) {
  state.tipoEnseñanzas = tipoEnseñanzas
}

export function setCursosActivos(state, cursosActivos) {
  state.cursosActivos = cursosActivos
}

export function setAsignaturasActivas(state, asignaturasActivas) {
  state.asignaturasActivas = asignaturasActivas
}

export function avanceError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.avances = []
}

export function avanceErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
