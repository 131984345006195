import axios from 'axios'

export default async function establecimientos() {
  return 0
}

export async function fetchEstablecimientos({ commit }) {
  try {
    const { data } = await axios.get('establecimientos')
    commit('setEstablecimientos', data)
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function fetchEstablecimiento({ commit }) {
  try {
    const { data } = await axios.get('establecimientos')
    commit('setEstablecimientos', data)
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function fetchEstablecimientosActivos({ commit }) {
  try {
    const { data } = await axios.get('establecimientos/activos')
    commit('setEstablecimientosActivos', data)
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

// export async function fetchEstablecimientosActivosUsuario({ commit }) {
//   try {
//     const { data } = await axios.get('establecimientos/activos/usuario')
//     commit('setEstablecimientosActivos', data)
//   } catch (e) {
//     commit('establecimientoError', e.response.data)
//   }
// }

export async function addEstablecimientos({ commit }, establecimiento) {
  try {
    commit('establecimientoErrorNull')
    await axios({
      method: 'POST',
      url: 'establecimientos',
      data: {
        rbd: establecimiento.rbd,
        nombre: establecimiento.nombre,
        insignia: establecimiento.insignia,
        correo: establecimiento.correo,
        telefono: establecimiento.telefono,
        direccion: establecimiento.direccion,
        dependencia: establecimiento.dependencia,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function updateEstablecimiento({ commit }, establecimiento) {
  try {
    commit('establecimientoErrorNull')
    await axios({
      method: 'PUT',
      url: `/establecimientos/${establecimiento.id}`,
      data: {
        rbd: establecimiento.rbd,
        nombre: establecimiento.nombre,
        insignia: establecimiento.insignia,
        correo: establecimiento.correo,
        telefono: establecimiento.telefono,
        direccion: establecimiento.direccion,
        dependencia: establecimiento.dependencia,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function updateEstablecimientoPeriodo({ commit }, establecimiento) {
  try {
    commit('establecimientoErrorNull')
    const fecha = new Date()
    const dia = fecha.getDate()
    const mes = (fecha.getMonth() + 1)
    const year = fecha.getFullYear()
    const fechaInicioPeriodoActivo = `${year}-${mes}-${dia}`
    await axios({
      method: 'PUT',
      url: `/establecimientos/periodoActivo/${establecimiento.id}`,
      data: {
        idPeriodoActivo: establecimiento.idPeriodoActivo,
        fechaInicioPeriodoActivo,
      },
    })
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function updateEstablecimientoEstado({ commit, dispatch }, establecimiento) {
  let est = ''
  try {
    commit('establecimientoErrorNull')
    if (establecimiento.estado === 'Inactivo') {
      est = 'Activo'
    } else if (establecimiento.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/establecimientos/${establecimiento.id}`,
      data: {
        nombre: establecimiento.nombre,
        estado: est,
      },
    })
    // actualizamos lista de establecimientos
    dispatch('fetchEstablecimientos')
    dispatch('fetchEstablecimientosActivas')
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function removeEstablecimientos({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/establecimientos/${id}`,
    })
    // actualizamos lista de establecimientos
    dispatch('fetchEstablecimientos')
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}
