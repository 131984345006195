import axios from 'axios'

export default async function diagnosticos() {
  return 0
}

export async function fetchDiagnosticos({ commit }) {
  try {
    const { data } = await axios.get('diagnosticos')
    commit('setDiagnosticos', data)
  } catch (e) {
    commit('diagnosticoError', e.response.data)
  }
}
