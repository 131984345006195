import store from '@/store'

export default [
  {
    path: '/objetivos',
    name: 'objetivos',
    component: () => import('@/views/objetivos/Objetivos.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Objetivos',
      breadcrumb: [
        {
          text: 'Objetivos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/objetivos/create',
    name: 'objetivos-create',
    component: () => import('@/views/objetivos/components/ObjetivosCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear objetivo',
      breadcrumb: [
        {
          text: 'Objetivos',
          to: '/objetivos',
        },
        {
          text: 'Crear Objetivo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/objetivos/clone',
    name: 'objetivos-clone',
    component: () => import('@/views/objetivos/components/ObjetivosClone.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Clonar objetivo',
      breadcrumb: [
        {
          text: 'Objetivos',
          to: '/objetivos',
        },
        {
          text: 'Clonar Objetivo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/objetivos/update',
    name: 'objetivos-update',
    component: () => import('@/views/objetivos/components/ObjetivosUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar objetivo',
      breadcrumb: [
        {
          text: 'Objetivos',
          to: '/objetivos',
        },
        {
          text: 'Editar Objetivo',
          active: true,
        },
      ],
    },
  },
]
