import axios from 'axios'

export default async function inquilinos() {
  return 0
}

export async function fetchInquilinos({ commit }) {
  try {
    const { data } = await axios.get('inquilinos')
    commit('setInquilinos', data)
  } catch (e) {
    commit('inquilinoError', e.response.data)
  }
}

export async function fetchInquilinosActivos({ commit }) {
  try {
    const { data } = await axios.get('inquilinos/activos')
    commit('setInquilinosActivos', data)
  } catch (e) {
    commit('inquilinoError', e.response.data)
  }
}

export async function addInquilinos({ commit }, inquilino) {
  try {
    commit('inquilinoErrorNull')
    await axios({
      method: 'POST',
      url: 'inquilinos',
      data: {
        nombre: inquilino.nombre,
        estado: inquilino.estado,
      },
    })
  } catch (e) {
    commit('inquilinoError', e.response.data)
  }
}

export async function updateInquilinos({ commit }, inquilino) {
  try {
    commit('inquilinoErrorNull')
    await axios({
      method: 'PUT',
      url: `/inquilinos/${inquilino.id}`,
      data: {
        nombre: inquilino.nombre,
        estado: inquilino.estado,
      },
    })
  } catch (e) {
    commit('inquilinoError', e.response.data)
  }
}

export async function updateInquilinoEstado({ commit, dispatch }, inquilino) {
  let est = ''
  try {
    commit('inquilinoErrorNull')
    if (inquilino.estado === 'Inactivo') {
      est = 'Activo'
    } else if (inquilino.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/inquilinos/${inquilino.id}`,
      data: {
        nombre: inquilino.nombre,
        estado: est,
      },
    })
    // actualizamos lista de inquilinos
    dispatch('fetchInquilinos')
    dispatch('fetchInquilinosActivas')
  } catch (e) {
    commit('inquilinoError', e.response.data)
  }
}

export async function removeInquilinos({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/inquilinos/${id}`,
    })
    // actualizamos lista de inquilinos
    dispatch('fetchInquilinos')
  } catch (e) {
    commit('inquilinoError', e.response.data)
  }
}
