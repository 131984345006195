export default function alumno() {
  return 0
}

export function getAlumnos(state) {
  return state.alumnos
}

export function getAlumnosActivos(state) {
  return state.alumnosActivos
}

export function getAlumnosCurso(state) {
  return state.alumnosCurso
}
