import axios from 'axios'

export default async function tipoEnseñanzas() {
  return 0
}

export async function fetchTipoEnseñanza({ commit }) {
  try {
    const { data } = await axios.get('tipoEnsenanza')
    commit('setTipoEnseñanzas', data)
  } catch (e) {
    commit('tipoEnseñanzaError', e.response.data)
  }
}
