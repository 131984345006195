export default function diagnostico() {
  return 0
}

export function getDiagnosticos(state) {
  return state.diagnosticos
}

export function getDiagnosticosAsignatura(state) {
  return state.diagnosticosAsignatura
}

export function getDiagnosticosActivos(state) {
  return state.diagnosticosActivos
}
