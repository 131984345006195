import store from '@/store'

export default [
  {
    path: '/alumnos',
    name: 'alumnos',
    component: () => import('@/views/alumnos/Alumnos.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Alumnos',
      breadcrumb: [
        {
          text: 'Alumnos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/alumnos/create',
    name: 'alumnos-create',
    component: () => import('@/views/alumnos/AlumnosCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear alumno',
      breadcrumb: [
        {
          text: 'Alumnos',
          to: '/alumnos',
        },
        {
          text: 'Crear Alumno',
          active: true,
        },
      ],
    },
  },
  {
    path: '/alumnos/update',
    name: 'alumnos-update',
    component: () => import('@/views/alumnos/AlumnosUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar alumno',
      breadcrumb: [
        {
          text: 'Alumnos',
          to: '/alumnos',
        },
        {
          text: 'Editar Alumno',
          active: true,
        },
      ],
    },
  },
]
