import axios from 'axios'

export default async function indicadorPersonalizado() {
  return 0
}

export async function fetchIndicadoresPersonalizadosObjetivo({ commit }, datos) {
  try {
    const {
      idObjetivo,
      idPeriodo,
      idCurso,
      tipoObjetivo,
    } = datos

    const { data } = await axios.get(`indicador/personalizado/${idObjetivo}/${idPeriodo}/${idCurso}/${tipoObjetivo}`)
    commit('setIndicadorPersonalizadoAll', data)
  } catch (e) {
    commit('indicadorPersonalizadoError', e.response.data)
  }
}

export async function fetchIndicadoresPersonalizadosObjetivoAprobados({ commit }, datos) {
  try {
    const {
      idObjetivo,
      idPeriodo,
      idCurso,
      tipoObjetivo,
    } = datos

    const { data } = await axios.get(`indicador/personalizado/aprobados/${idObjetivo}/${idPeriodo}/${idCurso}/${tipoObjetivo}`)

    commit('setIndicadorPersonalizadoObjetivoAprobados', data)
  } catch (e) {
    commit('indicadorPersonalizadoError', e.response.data)
  }
}

export async function addIndicadorPersonalizado({ commit }, indicador) {
  try {
    commit('indicadorPersonalizadoErrorNull')
    await axios({
      method: 'POST',
      url: 'indicador/personalizado',
      data: {
        nombre: indicador.nombre,
        idObjetivo: indicador.idObjetivo,
        idCurso: indicador.idCurso,
        idUsuario: indicador.idUsuario,
        idPeriodo: indicador.idPeriodo,
        tipo_objetivo: indicador.tipoObjetivo,
        estado: 'Aprobado',
      },
    })
  } catch (e) {
    commit('indicadorPersonalizadoError', e.response.data)
  }
}

export async function updateIndicadorPersonalizado({ commit }, indicador) {
  try {
    commit('indicadorPersonalizadoErrorNull')
    await axios({
      method: 'PUT',
      url: `/indicador/personalizado/${indicador.id}`,
      data: {
        nombre: indicador.nombre,
      },
    })
  } catch (e) {
    commit('indicadorPersonalizadoError', e.response.data)
  }
}

export async function removeIndicador({ commit }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/indicador/personalizado/${id}`,
    })
    // actualizamos lista de alumnos
    // dispatch('fetchAlumnos')
    // dispatch('fetchAlumnos')
  } catch (e) {
    commit('alumnoError', e.response.data)
  }
}
