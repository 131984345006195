export function setGrados(state, grados) {
  state.grados = grados
}

export function setGrado(state, grado) {
  state.selectedGrado = grado
}

export function gradoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.grados = []
}

export function gradoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
