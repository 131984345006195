export function setDiagnosticos(state, diagnosticos) {
  state.diagnosticos = diagnosticos
}

export function setDiagnosticosActivos(state, diagnosticos) {
  state.diagnosticosActivos = diagnosticos
}

export function setDiagnosticosAsignatura(state, diagnosticos) {
  state.diagnosticosAsignatura = diagnosticos
}

export function setDiagnostico(state, diagnostico) {
  state.selectedDiagnostico = diagnostico
}

export function updateDiagnosticoEstado(state, payload) {
  const diagnostico = state.diagnosticos.find(u => u.id === payload.id)
  if (diagnostico) {
    state.done = !diagnostico.done
  }
}

export function diagnosticoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.diagnosticos = []
}

export function diagnosticoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
