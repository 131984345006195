export default function indicador() {
  return 0
}

export function getIndicadores(state) {
  return state.indicadores
}

export function getIndicadoresObjetivo(state) {
  return state.indicadoresObjetivo
}

export function getIndicadoresPersonalizados(state) {
  return state.indicadoresPersonalizados
}
