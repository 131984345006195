import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules Vuexy
import app from './_app'
import appConfig from './_app-config'
import verticalMenu from './_vertical-menu'

// Modules APP
import auth from './:auth'
import authLibroDigital from './:authLibroDigital'
import inquilinos from './:inquilinos'

// TABLAS PADRE
import tipoEnseñanza from './:tipoEnseñanza'
import grados from './:grados'
import asignaturas from './:asignaturas'
import ejes from './:ejes'
import objetivos from './:objetivos'
import indicadores from './:indicadores'
import diagnosticosPie from './:diagnosticosPie'
import prioritarios from './:prioritarios'
import notasConversion from './:notasConversion'
import notas from './notas'

// DASH
import dashLdConexion from './dashLdConexion'

// SYS
import establecimientos from './establecimientos'
import periodos from './periodos'
import cursos from './cursos'
import usuarios from './usuarios'
import roles from './roles'
import alumnos from './alumnos'
import avances from './avances'
import puntajesIndicadores from './puntajesIndicadores'
import indicadorPersonalizado from './indicadorPersonalizado'
import informes from './informes'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default new Vuex.Store({
  modules: {
    // APP
    app,
    appConfig,
    verticalMenu,
    inquilinos,
    auth,
    authLibroDigital,

    // DASH
    dashLdConexion,

    // TABLAS PADRE
    tipoEnseñanza,
    grados,
    asignaturas,
    ejes,
    objetivos,
    indicadores,
    diagnosticosPie,
    prioritarios,
    notasConversion,

    // SYS
    establecimientos,
    notas,
    periodos,
    cursos,
    usuarios,
    roles,
    alumnos,
    avances,
    puntajesIndicadores,
    indicadorPersonalizado,
    informes,
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin],
})
