export default function establecimiento() {
  return 0
}

export function getEstablecimientos(state) {
  return state.establecimientos
}

export function getEstablecimientosActivos(state) {
  return state.establecimientosActivos
}
