export default function informe() {
  return false
}

export function setNombre(nombre) {
  this.nombreResumenAnual = nombre
}

export function setHtml(html) {
  this.htmlResumenAnual = html
}

export function informeError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.informe = []
}
