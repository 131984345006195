export function setNotas(state, notas) {
  state.notas = notas
}

export function setNotasAll(state, notas) {
  state.notasAll = notas
}

export function setResponse(state, payload) {
  state.status = payload.status
  state.message = payload.message
  state.cursoResponse = payload.curso
  state.gradoResponse = payload.grado
  state.notaResponse = payload.nota
}

export function notasError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.notas = []
}

export function notasErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
