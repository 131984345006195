import store from '@/store'

export default [
  {
    path: '/establecimientos',
    name: 'establecimientos',
    component: () => import('@/views/establecimientos/Establecimientos.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Establecimientos',
      breadcrumb: [
        {
          text: 'Establecimientos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/establecimientos/create',
    name: 'establecimientos-create',
    component: () => import('@/views/establecimientos/EstablecimientosCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Crear establecimiento',
      breadcrumb: [
        {
          text: 'Establecimientos',
          to: '/establecimientos',
        },
        {
          text: 'Crear Establecimiento',
          active: true,
        },
      ],
    },
  },
  {
    path: '/establecimientos/update',
    name: 'establecimientos-update',
    component: () => import('@/views/establecimientos/EstablecimientosUpdate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Editar establecimiento',
      breadcrumb: [
        {
          text: 'Establecimientos',
          to: '/establecimientos',
        },
        {
          text: 'Editar Establecimiento',
          active: true,
        },
      ],
    },
  },
  {
    path: '/establecimientos/config',
    name: 'establecimientos-config',
    component: () => import('@/views/establecimientos/EstablecimientosConfig.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      breadcrumb: [
        {
          text: 'Establecimientos',
          to: '/establecimientos',
        },
        {
          text: 'Configuraciones del Establecimiento',
          active: true,
        },
      ],
      pageTitle: 'Configuraciones',
    },
  },
]
