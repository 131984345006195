import axios from 'axios'

export default async function objetivos() {
  return 0
}

export async function fetchObjetivos({ commit }) {
  try {
    const { data } = await axios.get('objetivos')
    commit('setObjetivos', data)
  } catch (e) {
    commit('objetivoError', e)
  }
}

export async function fetchObjetivosAsignatura({ commit }, datos) {
  try {
    const {
      idAsignatura,
      idPeriodoActivo,
    } = datos

    const { data } = await axios.get(`objetivos/asignatura/${idAsignatura}/${idPeriodoActivo}`)
    commit('setObjetivosAsignatura', data)
    return data
  } catch (e) {
    commit('objetivoError', e.response.data)
  }
}

export async function fetchObjetivosTrabajados({ commit }, datos) {
  try {
    commit('objetivoErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'objetivos/trabajados',
      data: {
        idAsignatura: datos.idAsignatura,
        idPeriodo: datos.idPeriodo,
        idCurso: datos.idCurso,
        objetivos: datos.objetivos,
      },
    })
    return { status: 'success', data }
  } catch (e) {
    return e.response.data
  }
}

export async function fetchObjetivosBetwen({ commit }, datos) {
  try {
    const {
      idCursoInicio,
      idCursoFin,
    } = datos

    const { data } = await axios.get(`objetivos/betwen/${idCursoInicio}/${idCursoFin}`)
    commit('setObjetivosBetwen', data)
  } catch (e) {
    commit('objetivoError', e.response.data)
  }
}

export async function addObjetivoPersonalizado({ commit }, objetivo) {
  try {
    commit('objetivoErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'objetivos/personalizados',
      data: {
        nombre: objetivo.nombre,
        abreviatura: objetivo.abreviatura,
        priorizacion: objetivo.priorizacion,
        idEje: objetivo.idEje,
        idEstablecimiento: objetivo.idEstablecimiento,
        indicadores: objetivo.indicadores,
        estado: objetivo.estado,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('objetivoError', e.response.data)
  }
}

export async function updatePersonalizado({ commit }, objetivo) {
  try {
    const { data } = await axios({
      method: 'PUT',
      url: `/objetivos/personalizados/${objetivo.id}`,
      data: {
        nombre: objetivo.nombre,
        abreviatura: objetivo.abreviatura,
        priorizacion: objetivo.priorizacion,
        idEje: objetivo.idEje,
        idEstablecimiento: objetivo.idEstablecimiento,
        indicadores: objetivo.indicadores,
        estado: objetivo.estado,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('objetivoError', e.response.data)
  }
}

export async function updateEstadoMinisterio({ commit }, objetivo) {
  try {
    const {
      id,
      estado,
    } = objetivo
    const { data } = await axios({
      method: 'PUT',
      url: `/objetivos/estado/${id}`,
      data: {
        estado,
      },
    })

    commit('setResponse', data)
  } catch (e) {
    commit('objetivoError', e.response.data)
  }
}

export async function updateEstadoPersonalizado({ commit }, objetivo) {
  try {
    const {
      id,
      estado,
    } = objetivo
    const { data } = await axios({
      method: 'PUT',
      url: `/objetivos/personalizados/estado/${id}`,
      data: {
        estado,
      },
    })

    commit('setResponse', data)
  } catch (e) {
    commit('objetivoError', e.response.data)
  }
}

export async function updatePriorizacionInternaMinisterio({ commit }, objetivo) {
  try {
    const {
      id,
      priorizacion,
    } = objetivo
    const { data } = await axios({
      method: 'PUT',
      url: `/objetivos/priorizacion/interna/${id}`,
      data: {
        priorizacionInterna: priorizacion,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('objetivoError', e.response.data)
  }
}

export async function updatePriorizacionPersonalizado({ commit }, objetivo) {
  try {
    const {
      id,
      priorizacion,
    } = objetivo
    const { data } = await axios({
      method: 'PUT',
      url: `/objetivos/personalizados/priorizacion/${id}`,
      data: {
        priorizacion,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('objetivoError', e.response.data)
  }
}
