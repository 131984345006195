import axios from 'axios'

export default async function prioritarios() {
  return 0
}

export async function fetchPrioritarios({ commit }) {
  try {
    const { data } = await axios.get('prioritarios')
    commit('setPrioritarios', data)
  } catch (e) {
    commit('prioritarioError', e.response.data)
  }
}
