export default {
  objetivos: [],
  objetivosTrabajados: [],
  objetivosActivos: [],
  objetivosAsignatura: [],
  objetivosBetwen: [],
  selectedObjetivo: null,
  error: false,
  errorMessage: [],
  API_RESPONSE: null,
}
