export default function informes() {
  return 0
}

export function getHtmlResumenAnual(state) {
  return state.htmlResumenAnual
}

export function getNombreResumenAnual(state) {
  return state.nombreResumenAnual
}
