import axios from 'axios'

export default async function asignaturas() {
  return 0
}

export async function fetchAsignaturas({ commit }) {
  try {
    const { data } = await axios.get('asignaturas')
    commit('setAsignaturas', data)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function fetchAsignatura({ commit }) {
  try {
    const { data } = await axios.get('asignaturas')
    commit('setAsignaturas', data)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function fetchAsignaturasActivos({ commit }) {
  try {
    const { data } = await axios.get('asignaturas/activos')
    commit('setAsignaturasActivos', data)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

// * avances
// * Resumen aprendizajes
export async function fetchAsignaturasUsuario({ rootState }, idCurso) {
  try {
    const { idPeriodoHistorico } = rootState.periodos
    const { data } = await axios.get(`asignaturas/usuario/${idCurso}/${idPeriodoHistorico}`)
    return data
  } catch (e) {
    return e.response.data
  }
}

export async function fetchAsignaturasCurso({ rootState }, idCurso) {
  try {
    const { idPeriodoHistorico } = rootState.periodos
    const { data } = await axios.get(`asignaturas/curso/${idCurso}/${idPeriodoHistorico}`)
    console.log('data :', data)
    return data
  } catch (e) {
    return e.response.data
  }
}

export async function fetchDocentesAsignaturas({ commit }, idPeriodo) {
  try {
    const { data } = await axios.get(`asignaturas/getDocentesAsignaturas/${idPeriodo}`)
    commit('setAsignaturasActivos', data)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function fetchAsignaturasActivosGrado({ commit }, idGrado) {
  try {
    const { data } = await axios.get(`asignaturas/activos/grado/${idGrado}`)
    commit('setAsignaturasActivosGrado', data)
    return data
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

// export async function fetchAsignaturasActivosUsuario({ commit }) {
//   try {
//     const { data } = await axios.get('asignaturas/activos/usuario')
//     commit('setAsignaturasActivos', data)
//   } catch (e) {
//     commit('asignaturaError', e.response.data)
//   }
// }

export async function addAsignaturas({ commit }, asignatura) {
  try {
    commit('asignaturaErrorNull')
    await axios({
      method: 'POST',
      url: 'asignaturas',
      data: {
        rbd: asignatura.rbd,
        nombre: asignatura.nombre,
        insignia: asignatura.insignia,
        correo: asignatura.correo,
        telefono: asignatura.telefono,
        direccion: asignatura.direccion,
        dependencia: asignatura.dependencia,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function updateAsignaturas({ commit }, asignatura) {
  try {
    commit('asignaturaErrorNull')
    await axios({
      method: 'PUT',
      url: `/asignaturas/${asignatura.id}`,
      data: {
        rbd: asignatura.rbd,
        nombre: asignatura.nombre,
        insignia: asignatura.insignia,
        correo: asignatura.correo,
        telefono: asignatura.telefono,
        direccion: asignatura.direccion,
        dependencia: asignatura.dependencia,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function updateAsignaturaPeriodo({ commit }, asignatura) {
  try {
    commit('asignaturaErrorNull')
    const fecha = new Date()
    const dia = fecha.getDate()
    const mes = (fecha.getMonth() + 1)
    const year = fecha.getFullYear()
    const fechaInicioPeriodoActivo = `${year}-${mes}-${dia}`
    await axios({
      method: 'PUT',
      url: `/asignaturas/periodoActivo/${asignatura.id}`,
      data: {
        idPeriodoActivo: asignatura.idPeriodoActivo,
        fechaInicioPeriodoActivo,
      },
    })
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function updateAsignaturaEstado({ commit, dispatch }, asignatura) {
  let est = ''
  try {
    commit('asignaturaErrorNull')
    if (asignatura.estado === 'Inactivo') {
      est = 'Activo'
    } else if (asignatura.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/asignaturas/${asignatura.id}`,
      data: {
        nombre: asignatura.nombre,
        estado: est,
      },
    })
    // actualizamos lista de asignaturas
    dispatch('fetchAsignaturas')
    dispatch('fetchAsignaturasActivas')
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function removeAsignaturas({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/asignaturas/${id}`,
    })
    // actualizamos lista de asignaturas
    dispatch('fetchAsignaturas')
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}
