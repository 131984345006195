export default function indicador() {
  return 0
}

export function getIndicadorPersonalizado(state) {
  return state.indicadorPersonalizado
}

export function getIndicadorPersonalizadoAll(state) {
  return state.indicadorPersonalizadoAll
}

export function getIndicadorPersonalizadoObjetivoAprobados(state) {
  return state.indicadorPersonalizadoObjetivoAprobados
}
