import store from '@/store'

export default [
  {
    path: '/asignaturas',
    name: 'asignaturas',
    component: () => import('@/views/asignaturas/Asignaturas.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Asignaturas',
      breadcrumb: [
        {
          text: 'Asignaturas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asignaturas/create/ejes',
    name: 'asignaturas-create-ejes',
    component: () => import('@/views/asignaturas/AsignaturasCreateEjes.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login',
        })
      }
      return next()
    },
    meta: {
      pageTitle: 'Asignar ejes',
      breadcrumb: [
        {
          text: 'Asignaturas',
          to: '/asignaturas',
        },
        {
          text: 'Asignar ejes',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/asignaturas/create',
  //   name: 'asignaturas-create',
  //   component: () => import('@/views/asignaturas/AsignaturasCreate.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'login',
  //       })
  //     }
  //     return next()
  //   },
  //   meta: {
  //     pageTitle: 'Crear asignatura',
  //     breadcrumb: [
  //       {
  //         text: 'Asignaturas',
  //         to: '/asignaturas',
  //       },
  //       {
  //         text: 'Crear Asignatura',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/asignaturas/update',
  //   name: 'asignaturas-update',
  //   component: () => import('@/views/asignaturas/AsignaturasUpdate.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/authenticated']) {
  //       return next({
  //         name: 'login',
  //       })
  //     }
  //     return next()
  //   },
  //   meta: {
  //     pageTitle: 'Editar asignatura',
  //     breadcrumb: [
  //       {
  //         text: 'Asignaturas',
  //         to: '/asignaturas',
  //       },
  //       {
  //         text: 'Editar Asignatura',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
