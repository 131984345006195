import axios from 'axios'

export default async function notas() {
  return 0
}

export async function calcularNota({ commit }, dataNota) {
  try {
    const {
      idAlumno,
      idPeriodo,
      idCurso,
      idAsignatura,
      idObjetivo,
    } = dataNota
    const valores = `${idAlumno}/${idCurso}/${idAsignatura}/${idPeriodo}/${idObjetivo}`
    await axios.get(`notas/calcularNota/${valores}`)
  } catch (e) {
    commit('notasError', e.response.data)
  }
}

export async function calcularNotaCurso({ commit }, dataNota) {
  try {
    const {
      idPeriodo,
      idCurso,
      idAsignatura,
      idObjetivo,
    } = dataNota
    const valores = `${idCurso}/${idAsignatura}/${idPeriodo}/${idObjetivo}`
    await axios.get(`notas/calcularNotaCurso/${valores}`)
    // commit('setNotas', data)
  } catch (e) {
    commit('notasError', e.response.data)
  }
}

export async function updateNota({ commit }, datos) {
  try {
    const {
      idAlumno,
      idCurso,
      idAsignatura,
      idPeriodo,
      idEstablecimiento,
      idObjetivo,
      nota,
    } = datos
    await axios({
      method: 'POST',
      url: 'notas/updateNota',
      data: {
        idAlumno,
        idCurso,
        idAsignatura,
        idPeriodo,
        idObjetivo,
        idEstablecimiento,
        nota,
      },
    })
    // commit('setNotas', data)
  } catch (e) {
    commit('notasError', e.response.data)
  }
}

export async function fetchAll({ commit }, datos) {
  try {
    const {
      idCurso,
      idPeriodo,
    } = datos
    const { data } = await axios.get(`notas/getAll/${idPeriodo}/${idCurso}`)
    commit('setNotasAll', data)
  } catch (e) {
    commit('notasError', e.response.data)
  }
}

// * Resumen Avances
export async function fetchNotasAsignatura({ commit, rootState }, dataNota) {
  try {
    const { idPeriodoHistorico } = rootState.periodos
    const {
      idCurso,
      idAsignatura,
    } = dataNota

    const { data } = await axios.get(`notas/getNotasAsignatura/${idPeriodoHistorico}/${idCurso}/${idAsignatura}`)
    commit('setNotas', data)
  } catch (e) {
    commit('notasError', e.response.data)
  }
}

// * Resumen Anual
export async function fetchAllNotasCurso({ commit, rootState }, dataNota) {
  try {
    const { idPeriodoHistorico } = rootState.periodos
    const {
      // idPeriodo,
      idCurso,
    } = dataNota
    const { data } = await axios.get(`notas/getAllNotasCurso/${idPeriodoHistorico}/${idCurso}`)
    commit('setNotas', data)
  } catch (e) {
    commit('notasError', e.response.data)
  }
}

export async function updateNotasLibroDigital({ commit }, datos) {
  try {
    // const { data } = await axios.post('https://apilibrodigital.escuelarepublicadefrancia.cl/v1/updateNotasBrujula', datos)
    const { data } = await axios.request({
      headers: {
        Authorization: `Bearer ${datos.token}`,
      },
      method: 'POST',
      url: 'https://apilibrodigital.escuelarepublicadefrancia.cl/v1/updateNotasBrujula',
      data: datos,
    })
    // url: 'https://apilibrodigital.escuelarepublicadefrancia.cl/v1/updateNotasBrujula',
    commit('setResponse', data)
  } catch (e) {
    commit('notasError', e.response.data)
  }
}
