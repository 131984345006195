import axios from 'axios'

export default async function usuarios() {
  return 0
}

export async function fetchUsuarios({ commit }) {
  try {
    const { data } = await axios.get('usuarios')
    commit('setUsuarios', data)
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function fetchUsuariosActivos({ commit }) {
  try {
    const { data } = await axios.get('usuarios/activos')
    commit('setUsuariosActivos', data)
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function fetchDocentesActivos({ commit }) {
  try {
    const { data } = await axios.get('usuarios/docentes')
    commit('setDocentesActivos', data)
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function fetchDocenteAsignaturas({ commit }, datos) {
  try {
    const {
      id,
      idEstablecimientoActivo,
    } = datos
    const { data } = await axios.get(`usuarios/docente/asignaturas/${id}/${idEstablecimientoActivo}`)
    commit('setDocenteAsignaturas', data)
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function addUsuarios({ commit }, usuario) {
  try {
    commit('usuarioErrorNull')
    let idEstablecimiento = null
    if (usuario.idEstablecimiento !== null) {
      idEstablecimiento = usuario.idEstablecimiento
    }
    let asignaturas = null
    if (usuario.asignaturas !== null) {
      asignaturas = usuario.asignaturas
    }
    await axios({
      method: 'POST',
      url: 'usuarios',
      data: {
        email: usuario.email,
        password: usuario.password,
        avatar: usuario.avatar,
        rut: usuario.rut,
        nombres: usuario.nombres,
        primerApellido: usuario.primerApellido,
        segundoApellido: usuario.segundoApellido,
        rol: usuario.rol,
        idEstablecimiento,
        asignaturas,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function updateUsuariosVistas({ commit }, usuario) {
  try {
    commit('usuarioErrorNull')
    await axios({
      method: 'PUT',
      url: `/usuarios/vistas/${usuario.idUsuario}`,
      data: {
        idEstablecimientoActivo: usuario.establecimiento,
        rolActivo: usuario.rol,
        idPeriodoActivo: usuario.periodo,
      },
    })
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function updateUsuarios({ commit }, datos) {
  try {
    commit('usuarioErrorNull')

    let idEstablecimiento = null
    if (datos.idEstablecimiento !== null) {
      idEstablecimiento = datos.idEstablecimiento
    }
    const { usuario } = datos
    let asignaturas = null
    if (usuario.asignaturas !== null) {
      asignaturas = usuario.asignaturas
    }
    const { title } = usuario.nombreRol
    await axios({
      method: 'PUT',
      url: `/usuarios/${usuario.id}`,
      data: {
        email: usuario.email,
        avatar: usuario.avatar,
        rut: usuario.rut,
        nombres: usuario.nombres,
        primerApellido: usuario.primerApellido,
        segundoApellido: usuario.segundoApellido,
        nombreRol: title,
        idEstablecimiento,
        asignaturas,
      },
    })
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function updateUsuarioEstado({ commit, dispatch }, usuario) {
  let est = ''
  try {
    commit('usuarioErrorNull')
    if (usuario.estado === 'Inactivo') {
      est = 'Activo'
    } else if (usuario.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/usuarios/${usuario.id}`,
      data: {
        nombre: usuario.nombre,
        estado: est,
      },
    })
    // actualizamos lista de usuarios
    dispatch('fetchUsuarios')
    dispatch('fetchUsuariosActivas')
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function removeUsuarios({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/usuarios/${id}`,
    })
    // actualizamos lista de usuarios
    dispatch('fetchUsuarios')
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}
