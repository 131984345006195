export default function prioritario() {
  return 0
}

export function getPrioritarios(state) {
  return state.prioritarios
}

export function getPrioritariosAsignatura(state) {
  return state.prioritariosAsignatura
}

export function getPrioritariosActivos(state) {
  return state.prioritariosActivos
}
