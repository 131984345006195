export default function asignatura() {
  return 0
}

export function getAsignaturas(state) {
  return state.asignaturas
}

export function getAsignaturasActivos(state) {
  return state.asignaturasActivos
}

export function getAsignaturasActivosGrado(state) {
  return state.asignaturasActivosGrado
}
