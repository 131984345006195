export function setAlumnos(state, alumnos) {
  state.alumnos = alumnos
}

export function setAlumnosActivos(state, alumnos) {
  state.alumnosActivos = alumnos
}

export function setAlumno(state, alumno) {
  state.selectedAlumno = alumno
}

export function setAlumnosCurso(state, alumno) {
  state.alumnosCurso = alumno
}

export function updateAlumnoEstado(state, payload) {
  const alumno = state.alumnos.find(u => u.id === payload.id)
  if (alumno) {
    state.done = !alumno.done
  }
}

export function alumnoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.alumnos = []
}

export function alumnoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
