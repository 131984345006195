export function setUsuarios(state, usuarios) {
  state.usuarios = usuarios
}

export function setUsuario(state, usuario) {
  state.selectedUsuario = usuario
}

export function setDocentesActivos(state, docentes) {
  state.docentesActivos = docentes
}

export function setDocenteAsignaturas(state, asignaturas) {
  state.docenteAsignaturas = asignaturas
}

export function updateUsuarioEstado(state, payload) {
  const usuario = state.usuarios.find(u => u.id === payload.id)
  if (usuario) {
    state.done = !usuario.done
  }
}

export function usuarioError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.usuarios = []
}

export function usuarioErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
