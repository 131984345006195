// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import home from './routes/home'
import login from './routes/login'
import usuarios from './routes/usuarios'
import inquilinos from './routes/inquilinos'
import establecimientos from './routes/establecimientos'
import cursos from './routes/cursos'
import error404 from './routes/error-404'
import ajustes from './routes/ajustes'
import asignaturas from './routes/asignaturas'
import objetivos from './routes/objetivos'
import alumnos from './routes/alumnos'
import avances from './routes/avances'
import resumen from './routes/resumen'
import resumenAnual from './routes/resumenAnual'
import informehogar from './routes/informehogar'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...home,
    ...ajustes,
    ...inquilinos,
    ...login,
    ...usuarios,
    ...establecimientos,
    ...cursos,
    ...asignaturas,
    ...objetivos,
    ...alumnos,
    ...avances,
    ...resumen,
    ...resumenAnual,
    ...informehogar,
    ...error404,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  localStorage.setItem('userData', JSON.stringify(store.getters['auth/user']))
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
